import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1058.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1058.000000) scale(0.100000,-0.100000)">
          
          
<path d="M4455 6348 c-3 -7 -4 -395 -3 -863 l3 -850 110 0 110 0 6 705 c4 489
9 715 17 739 17 50 38 78 80 105 48 31 113 38 235 27 137 -13 185 -42 213
-131 5 -14 11 -344 14 -735 l5 -710 106 -3 c80 -2 109 1 117 11 8 10 12 218
14 728 3 796 -1 747 74 804 36 28 52 33 120 38 101 7 213 -5 261 -30 27 -13
45 -34 63 -69 l25 -49 3 -710 c2 -546 5 -711 15 -717 6 -4 55 -8 108 -8 71 0
100 4 108 14 9 10 11 197 9 727 -3 788 -1 754 -67 838 -44 55 -100 90 -196
122 l-86 29 -730 0 c-574 0 -731 -3 -734 -12z"/>
<path d="M6715 6341 c-130 -38 -203 -91 -253 -186 l-27 -50 -3 -724 c-2 -535
0 -727 9 -737 16 -19 202 -20 218 -1 7 10 12 157 13 483 l3 469 349 3 c237 1
353 -1 360 -8 7 -7 13 -169 16 -483 l5 -472 110 0 110 0 0 740 0 740 -22 40
c-26 46 -78 105 -93 105 -5 0 -10 4 -10 8 0 16 -79 53 -156 72 -67 17 -109 20
-323 19 -203 0 -256 -3 -306 -18z m566 -144 c89 -38 121 -124 117 -310 -1 -65
-5 -122 -8 -127 -4 -7 -137 -9 -361 -8 l-354 3 -3 135 c-4 176 7 230 58 273
56 47 120 57 336 52 129 -2 191 -8 215 -18z"/>
<path d="M7787 6353 c-4 -3 -7 -385 -7 -848 0 -670 3 -845 13 -859 12 -16 41
-17 437 -14 l425 4 73 26 c79 28 166 88 206 141 55 72 57 104 54 724 l-3 568
-28 57 c-46 94 -124 151 -258 188 -69 19 -102 20 -490 20 -228 0 -419 -3 -422
-7z m838 -152 c55 -18 102 -73 115 -135 8 -35 10 -226 8 -601 l-3 -550 -29
-47 c-51 -80 -60 -82 -384 -86 -210 -3 -287 -1 -297 8 -22 18 -22 1366 -1
1395 20 27 89 33 341 31 138 -2 224 -7 250 -15z"/>
<path d="M9145 6348 c-3 -7 -4 -395 -3 -863 l3 -850 106 -3 c81 -2 109 1 118
11 8 11 10 233 9 863 l-3 849 -113 3 c-84 2 -114 -1 -117 -10z"/>
<path d="M3090 6150 c0 -26 3 -30 29 -30 30 0 45 19 35 45 -4 9 -19 15 -35 15
-26 0 -29 -4 -29 -30z"/>
<path d="M3236 5541 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M3180 5500 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M3108 5483 c-25 -3 -42 -11 -49 -24 -6 -12 -27 -27 -47 -35 -85 -35
-152 -70 -149 -79 2 -5 21 -18 43 -28 108 -48 110 -54 63 -195 -61 -187 -81
-226 -130 -247 -41 -19 -50 -39 -23 -49 13 -5 15 -15 11 -44 -5 -43 -121 -283
-152 -316 -14 -15 -42 -25 -100 -34 -118 -18 -195 -35 -195 -43 0 -3 35 -12
78 -18 89 -15 122 -28 122 -50 0 -9 -19 -44 -43 -77 -55 -79 -188 -244 -197
-244 -3 0 -31 -33 -60 -72 -30 -40 -83 -103 -119 -141 -97 -101 -76 -100 95 8
49 31 105 71 125 90 57 54 182 196 211 240 36 54 78 135 78 150 0 7 9 20 19
29 19 17 24 17 108 -4 48 -12 108 -27 133 -35 178 -53 239 -72 345 -107 124
-42 288 -103 386 -144 61 -26 84 -29 94 -14 7 12 -81 57 -245 127 -238 102
-555 204 -737 239 -27 5 -31 30 -5 38 9 2 69 14 132 26 63 11 135 25 160 30
25 5 97 19 160 29 63 11 164 29 224 41 61 11 113 20 118 20 15 0 8 21 -9 26
-30 9 -94 35 -173 69 -107 46 -158 68 -305 127 -116 48 -130 56 -133 79 -3 24
8 33 115 94 221 126 325 190 326 200 1 5 -54 32 -123 61 -206 84 -205 83 -208
126 -4 49 21 95 63 115 29 14 58 46 37 41 -4 -1 -24 -3 -44 -5z m-73 -113 c8
-13 -13 -50 -29 -50 -7 0 -20 8 -29 18 -13 14 -14 21 -5 30 14 14 54 16 63 2z
m188 -187 c62 -28 72 -49 35 -73 -57 -36 -226 -127 -246 -132 -34 -9 -36 8
-13 103 11 46 23 99 26 116 12 61 33 59 198 -14z m-185 -449 c59 -25 145 -62
192 -81 95 -38 120 -57 103 -74 -6 -6 -85 -24 -175 -40 -90 -16 -197 -36 -238
-45 -41 -9 -88 -14 -105 -12 -26 2 -29 7 -26 28 1 13 16 51 32 82 16 32 39 82
50 110 22 57 36 78 51 78 5 0 57 -21 116 -46z"/>
<path d="M4594 4258 c-6 -10 6 -15 56 -23 25 -4 51 -12 58 -17 9 -8 12 -67 12
-235 0 -147 3 -222 10 -218 6 4 10 76 10 183 1 252 12 292 81 292 30 0 52 20
22 20 -203 4 -246 4 -249 -2z"/>
<path d="M5595 4244 c55 -27 56 -33 54 -243 -1 -174 -3 -195 -20 -212 -11 -11
-26 -16 -36 -13 -15 5 -16 4 -3 -5 18 -13 147 -15 155 -2 3 5 -7 11 -22 13
-52 6 -59 38 -53 258 3 107 4 200 2 206 -2 6 -26 12 -55 13 l-52 1 30 -16z"/>
<path d="M5192 4094 c9 -9 24 -14 32 -11 27 11 17 27 -16 27 -30 0 -31 -1 -16
-16z"/>
<path d="M6088 4102 c-53 -14 -67 -48 -67 -158 0 -107 11 -154 41 -173 24 -15
157 -16 171 -2 8 8 -11 11 -66 11 -66 0 -79 3 -97 22 -22 24 -26 74 -7 99 14
18 16 19 57 19 17 0 30 5 30 10 0 6 -20 10 -44 10 -48 0 -56 9 -56 61 0 69 36
94 110 78 63 -13 80 -30 80 -78 0 -31 -5 -40 -25 -49 -14 -7 -32 -12 -40 -12
-24 0 -17 -17 8 -22 39 -7 64 8 71 42 18 91 -12 138 -90 145 -27 2 -61 1 -76
-3z"/>
<path d="M6575 4102 c-16 -2 -40 -13 -52 -25 -21 -19 -23 -29 -23 -143 0 -157
6 -166 109 -172 48 -3 71 -1 71 7 0 7 -21 11 -54 11 -39 0 -60 6 -80 21 -25
20 -26 24 -26 128 0 130 11 147 100 154 30 2 56 10 58 16 4 12 -20 12 -103 3z"/>
<path d="M6981 4103 c-8 -3 -25 -18 -37 -32 -22 -24 -23 -35 -24 -144 0 -96 3
-119 15 -123 8 -4 15 -12 15 -19 0 -18 46 -29 115 -26 88 4 106 24 112 130 6
97 -2 158 -25 188 -14 17 -31 23 -87 27 -38 3 -76 3 -84 -1z m138 -41 c24 -24
29 -38 35 -107 8 -89 -7 -145 -43 -165 -33 -17 -116 -12 -142 9 -20 16 -23 28
-25 112 -4 148 5 163 96 172 19 2 39 5 43 5 5 1 21 -10 36 -26z"/>
<path d="M7336 4096 c3 -9 10 -16 15 -16 5 0 9 -70 9 -155 0 -97 4 -155 10
-155 6 0 10 8 10 18 5 171 14 278 24 284 18 12 51 9 72 -6 16 -12 20 -29 22
-110 2 -53 8 -96 13 -96 5 0 9 41 9 90 0 77 3 93 20 110 21 21 29 23 55 14 22
-9 45 -56 35 -74 -10 -20 2 -159 13 -143 4 7 8 53 8 102 1 108 -15 141 -66
141 -18 0 -38 -6 -44 -14 -10 -11 -18 -11 -54 1 -23 8 -68 17 -100 20 -50 5
-57 4 -51 -11z"/>
<path d="M7930 4103 c-31 -12 -60 -47 -60 -75 0 -38 35 -73 110 -110 41 -20
70 -50 70 -73 0 -32 -41 -65 -81 -65 -31 -1 -32 -2 -16 -14 24 -18 75 -5 100
26 18 21 19 63 3 100 -9 21 -94 70 -107 62 -5 -3 -9 1 -9 9 0 7 -8 22 -19 33
-16 18 -17 24 -6 51 7 17 18 34 24 38 19 11 79 -15 100 -43 l20 -27 1 27 c0
49 -71 82 -130 61z"/>
<path d="M5260 4092 c0 -5 11 -18 25 -30 62 -54 31 -122 -58 -122 -35 0 -47
-4 -44 -12 2 -7 10 -12 16 -10 6 1 36 3 67 3 63 2 74 13 74 77 0 41 -33 97
-51 87 -5 -4 -9 -1 -9 4 0 6 -4 11 -10 11 -5 0 -10 -4 -10 -8z"/>
<path d="M5121 4076 c-15 -18 -25 -151 -17 -220 10 -75 32 -96 102 -96 29 0
56 5 59 10 4 6 -12 10 -42 10 -27 0 -58 7 -70 15 -30 21 -35 73 -10 107 20 27
20 28 3 47 -18 20 -19 64 -1 119 8 25 -6 30 -24 8z"/>
<path d="M7860 3843 c1 -20 46 -80 55 -72 3 3 -3 24 -13 47 -17 42 -42 57 -42
25z"/>
<path d="M7504 3824 c-4 -14 -2 -35 4 -47 9 -19 11 -15 11 26 1 52 -5 61 -15
21z"/>
<path d="M7630 3823 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
<path d="M3285 3765 c-82 -14 -185 -23 -565 -51 -418 -30 -622 -61 -865 -133
-71 -22 -159 -47 -195 -55 -93 -24 -312 -95 -330 -107 -18 -13 -4 -11 165 26
161 35 358 69 480 84 55 7 127 16 160 21 217 35 722 64 1075 64 319 -1 513
-11 790 -44 91 -10 217 -22 280 -25 114 -6 144 -10 334 -40 86 -14 120 -15
105 -1 -7 6 -204 76 -344 121 -199 65 -272 84 -370 94 -50 6 -151 20 -225 31
-176 27 -390 34 -495 15z"/>
<path d="M5280 3770 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>

</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
